// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  padding-bottom: 2em;
  flex-direction: column;
  @include full-width-layout-container;
}

.search-nav {
  display: none;

  @include not-mobile {
    display: block;
  }
}

.title-padding {
  height: 1px;
  margin: 1em 0;

  @media (max-width: $breakpoint-tablet) {
    margin: .4em 0;
  }
}

.title-sort-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .page-title {
    font-size: 1.75em;
    text-transform: capitalize;

    &.exp-theme {
      font-weight: 700;
    }

    @include large-desktop {
      font-size: 2em;
    }
    @include tablet {
      font-size: 1.5em;
    }
    @include mobile {
      font-size: 1.25em;
    }
  }

  .page-subtitle {
    font-size: 1.1em;
    position: relative;
    margin: .75em 0 1.5em;
    @include large-desktop {
      font-size: 1.2em;
    }
    @include tablet {
      font-size: 1em;
    }
    @include mobile {
      font-size: .9em;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -14px;
      left: 0;
      width: var(--themes-area-page-line-width);
      height: .2em;
      background-color: var(--themes-colors-primary);

      @include tablet {
        bottom: -10px;
      }
      @include mobile {
        bottom: -7px;
      }
    }
  }

  .sub-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .sort-by {
      margin-right: 1em;

      @include mobile {
        margin-right: .5em;
      }
    }

    .mobile-filter-btn {
      margin-right: .5em;

      @include not-mobile {
        display: none;
      }
    }
  }
}

.no-listings-indicator {
  margin: .5em 0;
  opacity: .6;
}

.guide-wrapper {
  margin: 2em 0 4em;
}

.area-guide {
  max-width: $max-width-text;
  margin: 0 auto !important;
}

.area-blurb p {
  max-width: $max-width-text;
  line-height: 1.7em;
  margin: 0 auto 2em;
}

.area-blurb-heading {
  font-size: 1.75em;
  max-width: $max-width-text;
  margin: 0 auto 1em;
  font-family: $font-family-serif;
  @include mobile {
    font-size: 1.25em;
  }
}
