@import 'src/styles/imports';

.component {
  padding: 20px 40px 60px;
}

.list {
  padding-top: 20px;
  column-count: 4;
  column-gap: 40px;

  @include mobile {
    column-count: 2;
  }
}

.item {
  font-size: 16px;
  margin-bottom: 20px;
  color: $color-black;
  break-inside: avoid;
}

.item a {
  color: $color-black;
  font-weight: normal;
  text-decoration: none;
}

.item a:hover {
  text-decoration: underline;
}

.label {
  font-size: 14px;
  color: $color-grayish-blue;
}

.title {
  font-size: 24px;
  padding-bottom: 20px;
  font-weight: $font-weight-bold;
}
