@import 'src/styles/imports';

.component {
  font-size: 14px;
  left: 0;
  width: 100%;
  padding: .4em 0;
  background: var(--themes-colors-primary-background);

  &.zoocasa-theme {
    box-shadow: var(--themes-box-shadow);
  }

  &.exp-theme {
    @include mobile {
      margin-top: 1.5em;
    }
  }

  .layout-container {
    @include mobile {
      display: inline-block;
      overflow: scroll;
      max-width: 350px;
      white-space: nowrap;
    }
    @include full-width-layout-container;
  }

  .item span {
    max-width: 10px;
    text-overflow: ellipsis;
  }

  .item:last-child a {
    font-weight: $font-weight-bold;
  }

  a {
    display: inline-block;
    padding: .3em;
    color: var(--themes-colors-primary-font-color);
  }

  a:hover {
    color: var(--themes-colors-primary-font-color);
    text-decoration: underline;
  }

  .arrow {
    height: .7em;

    path {
      fill: var(--themes-colors-primary-font-color);
    }
  }
}
