@import 'src/styles/imports';

.container {
  position: relative;
  display: flex;
  margin: 1em 0 0;
  flex-direction: row;
  justify-content: flex-end;

  @include not-desktop {
    margin: 1.5em 0;
    justify-content: center;
  }
}

.component {
  .avatar-button { // <button> tag
    display: flex;
    padding: 0; // overwrite default padding for <button>
    align-items: center;

    .avatar-small {
      width: 50px;
      height: 50px;
      border: 1px solid $color-black-10;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name {
      font-size: 16px;
      font-weight: $font-weight-bold;
    }
  }
}

.popover {
  position: absolute;
  z-index: $z-index-bump;
  top: 120%;
  right: 0;
  box-shadow: var(--themes-box-shadow-light);
  min-width: 270px;
  padding: 18px;
  border-radius: var(--themes-buttons-border-radius-medium);
  background-color: $color-white;

  .close-button {
    position: absolute;
    top: 1.1em;
    right: 1.1em;
    width: 14px;
    height: 14px;
    padding: 0;
    font-weight: $font-weight-light;
    cursor: pointer;
  }

  .popover-title {
    font-size: 14px;
    margin-bottom: 15px;
    color: $color-black-75;
  }

  .popover-content {
    display: flex;
    flex-direction: row;

    .avatar-large {
      width: 65px;
      height: 65px;
      border: 1px solid $color-black-10;
      border-radius: 50%;
      margin-right: 13px;
    }

    .popover-agent-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .agent-name {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: $font-weight-bold;
      }

      .agent-title {
        font-size: 14px;
        color: $color-black-75;
      }
    }
  }
}

.mobile-bottom-popup {
  position: fixed;
  z-index: $z-index-11;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-black-75;

  .popover { // overwriting default
    position: fixed;
    top: 82%;
    bottom: 0;
    left: 0;
    padding: 30px 20px;
    border-radius: 20px 20px 0 0;

    .close-button {
      top: 1.5em;
    }
  }
}
