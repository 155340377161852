// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-panel;
  top: 3.6em;
  overflow-y: scroll;
  width: 100%;
  min-width: 25em;
  max-width: 100%;
  height: 10em;
  min-height: calc(100vh - 12em);
  max-height: calc(90vh - #{$height-top-nav * 2});
  padding-bottom: 1em;
  background: $color-white;

  @include not-mobile {
    box-shadow: $box-shadow;
    height: auto;
    min-height: auto;
    border-radius: var(--themes-buttons-border-radius-medium);
  }

  .loading-spinner {
    font-size: 1em;
  }

  .warning {
    font-size: .875em;
    position: relative;
    display: flex;
    height: auto;
    line-height: 1.5em;
    padding: .75em 0 .75em .75em;
    margin: 0;
    color: $color-grey-10;
    text-decoration: none;

    strong {
      font-weight: $font-weight-bold;
    }

    svg {
      width: 1.5em !important;
      height: 1.5em !important;
      margin-right: .5em;

      path {
        fill: $color-black !important;
      }
    }
  }
}

.component-small-width {
  min-width: 0;
}
